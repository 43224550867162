import { Link } from "react-router-dom";
import "../styles/css/landing.css";
import BlobbedBackground from "../utils/blobbedBackground";

import { showWindow } from "../utils/functions";
import { useEffect } from "react";

const Landing = ({ verify, handleClick }) => {
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/bll8yl3mzo.jsonp';
        script1.async = true;

        const script2 = document.createElement('script');
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;

        document.body.appendChild(script1);
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);
    return (
        <BlobbedBackground id="landing_section" section_type="section">
            <h1>Join The Butt Talks Community</h1>
            <h3>Super Charge Your Poop Health</h3>

            <div id="video">
                <div className="wistia_embed wistia_async_bll8yl3mzo seo=true videoFoam=false" style={{ height: '100%', width: '100%' }}>
                    <div className="wistia_swatch" style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
                        <img src="https://fast.wistia.com/embed/medias/bll8yl3mzo/swatch" style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="" aria-hidden="true" />
                    </div>
                </div>

            </div>


            {verify === true ? (
                <button className="join_button" onClick={handleClick}>Ready to join now</button>
            ) : (
                <Link to="/subscription">
                    <button className="join_button">Ready to join now</button>
                </Link>)}
        </BlobbedBackground>
    );
}

export default Landing;