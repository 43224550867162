import { Link } from "react-router-dom";
import "../styles/css/benefits.css";

const card_list = [
    "Exclusive Live Calls With Nurse Wong For Personal Health Advice",
    "On-Demand Access To Dozens Of Poop Health Training Videos",
    "Extensive Database Of Official Butt Talks Poop Health Tips",
    "Premium Upgrade To Butt Talks App To Better Track Your Health Goals",
    "Special Community Prizes And Giveaways For Free Butt Talks Merch",
    "Private In-Person Meet Ups With Nurse Wong",
    "Professional Industry Speakers To Help You Heal",
    "Direct Access to Official Butt Talks Support Team",
    "Anonymous Community Of Supportive Butt Talks Members"
]

const Card = ({ text }) => {
    return (
        <div className="card">
            <img src="./check.svg" alt="Check" />
            <p>{text}</p>
        </div>
    );
}

const MemberBenefits = () => {
    return (
        <section id="benefits_section">
            <h1>Member Benefits</h1>

            <div id="card_wrapper">
                {
                    card_list.map(data => <Card text={data} />)
                }
            </div>

            <Link to="/subscription">
                <button className="join_button">Ready to join now</button>
            </Link>
        </section>
    );
}

export default MemberBenefits;