import { Link } from "react-router-dom";
import "../styles/css/zoomMeetings.css";

const ZoomMeetings = ({ handleClick, verify }) => {
    const toMembership = () => {
        // Call the function passed as a prop
        handleClick();
    };
    return (
        <section id="zoom_section">
            <h1>Zoom Meeting</h1>

            <span>
                <div className="info_wrapper">
                    <h1>1-on-1 With Nurse Wong!</h1>
                    <p style={{ padding: '10px', paddingLeft: '0px' }}>You are NOT alone! Join exclusive video calls with Nurse Wong for personalized poop health advice!</p>
                    <br />
                    <p style={{ padding: '10px', paddingLeft: '0px' }}>With her expertise, you’ll be able to create an easy and effective health plan catered to you. Nurse Wong will say goodbye to your poop problems in NO TIME!</p>
                </div>

                <img src="./zoom_meeting.png" alt="Zoom Meeting" />
            </span>


            {verify === true ? (
                <button className="join_button" onClick={toMembership}>Ready to join now</button>
            ) : (
                <Link to="/subscription">
                    <button className="join_button">Ready to join now</button>
                </Link>
            )
            }

        </section>
    );
}

export default ZoomMeetings;