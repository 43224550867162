import "../styles/css/what_is.css";
import InfoStructure from "../utils/infoStructure";


const cellData = [
    {
        "title": "Butt Talks",
        "text": "Our goal at Butt Talks is to solve the world's pooping problems by demystifying the taboo through education and open discussion."
    },

    {
        "title": "Our Community",
        "text": "We have helped thousands of people through our app, social media, monthly zoom meetings, and a ton of helpful video content all specifically geared to solve  your pooping problems."
    }
]

const WhatIs = (props) => {

    return (
        <InfoStructure
            title="What is Butt Talks?"
            subtitle="Super Charge Your Poop Health"
            img="./butt-talk-gif.gif"
            handleClick={props.handleClick}
            verify={props.verify}


            cellData1={cellData[0]}
            cellData2={cellData[1]}
        />
    );
}

export default WhatIs;