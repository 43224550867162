import Landing from "../components/landing"
import AppSection from '../components/appSection';
import Discount from "../components/discount"
import FeaturedIn from '../components/featuredIn';
import Footer from '../components/footer';
import MeetNurse from '../components/meetNurse';
import MemberBenefits from '../components/member_benefits';
import Socials from '../components/socials';
import Testimonials from '../components/testimonials';
import WhatIs from '../components/what_is';
import ZoomMeetings from '../components/zoomMeetings';
import SignUpPopup from "../components/signupPopup";

const today = new Date();

// Add 7 days to today's date
const nextWeek = new Date(today.getTime() + 120 * 60 * 1000);

// Format the date into the desired format
const formattedDate = nextWeek.toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Set to true if you want 12-hour format with AM/PM
});

const LandingPage = () => {

    return (
        <>
            <SignUpPopup />

            <main>
                <Landing verify={false} />

                {formattedDate && (<Discount countDownDate={formattedDate} />)}

                <MemberBenefits />
                <WhatIs verify={false} />
                <Socials />
                <AppSection verify={false} />
                <ZoomMeetings verify={false} />
                <MeetNurse verify={false} />
                <FeaturedIn />
                <Testimonials verify={false} />
            </main>

            <Footer />
        </>
    );
}

export default LandingPage;