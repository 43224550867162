import "../styles/css/featuredIn.css";

const FeaturedIn = () => {
    return (
        <section id="featuredIn_section">
            <h1>Featured in</h1>

            <span>
                <div>
                    <img src="./background_images/amazon_logo.svg" alt="Amazon" />
                </div>
                <div>
                    <img src="./background_images/parade_logo.png" alt="Parade" />
                </div>
                <div>
                    <img src="./background_images/usawire.png" alt="USAWIRE" />
                </div>
                <div>
                    <img src="./background_images/examiner_logo.png" alt="Examiner" />
                </div>

            </span>
            <span>
                <div>
                    <img src="./background_images/simon&schuster_logo.png" alt="Simon & Schuster" />
                </div>
                <div>
                    <img src="./background_images/smiths_logo.png" alt="Smiths" />
                </div>
                <div>
                    <img src="./background_images/ulysses_logo.png" alt="Ulysses" />
                </div>
            </span>
        </section>
    );
}

export default FeaturedIn;