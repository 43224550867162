import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LandingPage from "./pages/landingPage";
import SubscriptionPage from './pages/subscriptionPage';
import ScrollToTop from './components/scrolToTop';
import { useEffect, useState } from 'react';
import ThanksPage from './pages/thanksPage';

const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [compteur, setCompteur] = useState(0);
  const [timer, setTimer] = useState(9000);
  const [first, setFirst] = useState(false);
  const data = [
    {
      name: 'John',
      location: 'San Jose, California',
      image: 'https://cdn-provesrc.nyc3.cdn.digitaloceanspaces.com/maps/7d7bf1ed029e971cbeddb0647dc24158-united%20states,california,san%20jose.png'

    },
    {
      name: 'Ericka',
      location: 'Phoenix Arizona',
      image: 'https://www.google.com/maps/d/u/0/mapimage?mid=1EB_ew_JZfg86ZNdIv_QA2p75pdo&llsw=33.255864,-112.399023&llne=33.607135,-111.702077&w=758&h=576&scale=4&hl=en_US'

    },
    {
      name: 'Lance',
      location: 'Brooklyn, New York',
      image: 'https://cdn-provesrc.nyc3.cdn.digitaloceanspaces.com/maps/f3869c640efc799824059c7a8ab7d3c3-united%20states,new%20york,new%20york.png'

    },
    {
      name: 'Qiana',
      location: 'Austin, Texas',
      image: 'https://www.google.com/maps/d/u/0/mapimage?mid=1EB_ew_JZfg86ZNdIv_QA2p75pdo&llsw=30.215762,-97.810717&llne=30.312993,-97.629136&w=758&h=576&scale=4&hl=en_US'

    },
    {
      name: 'Ryan',
      location: 'Boston Massachusetts',
      image: 'https://www.steinesthetics.com/_cache/static_map_afc085119500658e3cd35a1c415fc037.png'

    },
    {
      name: 'Mark',
      location: 'Kansas City Missouri',
      image: 'https://www.itseasy.com/public/images/static_maps/KansasCityMissouriUS.jpg'

    },
    {
      name: 'Jamie',
      location: 'Toronto Ontario',
      image: 'https://pizzaiolo.ca/system/uploads/staticmap/15_13.png'

    },
    {
      name: 'Rachel',
      location: 'Park City Utah',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE453Xt9LlGCjOQE2Wz_Gdytx41XUrs0ZR8rEQyQ95Tw&s'

    },
    {
      name: 'Kai',
      location: 'Oahu Hawaii',
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpVZpE3Xm8ndwGGIdHRpOfJ9G-neWyye0CIpx2rwXlwQ&s'

    },
    {
      name: 'Jesus',
      location: 'Puerto Vallarta Jalisco',
      image: 'https://www.bariatricshealthmexico.com/_cache/static_map_246b1a6c376a20ae1ee34a17e2a9f861.png'

    },
  ]


  useEffect(() => {
    // Function to show the pop-up after 5 seconds
    const showPopup = () => {
      setIsVisible(true);
      // Automatically close the pop-up after 3 seconds
      setTimeout(() => {
        setIsVisible(false);
        if (compteur === 9) {
          setCompteur(0)
        } else {
          setCompteur((prevCompteur) => (prevCompteur + 1) % 10);
        }
      }, 8000);
    };

    const interval = setInterval(() => {
      showPopup();
    }, timer);

    // Clear the interval when component unmounts to avoid memory leaks
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {isVisible && (
        <div className="popup">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 0.5 }}> <img style={{ height: '70px' }} src={data[compteur].image} /></div>
            <div style={{ flex: 1 }}>
              <p style={{ fontSize: '15px', fontWeight: 'bold' }}>{data[compteur].name} <span style={{ fontWeight: 'normal' }}>({data[compteur].location})</span> <br />
                <span style={{ fontSize: '10', fontWeight: 'normal' }}>Just Joined Butts Talks! </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <ScrollToTop />

      <Routes>

        <Route exact path="/" element={<LandingPage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/thanks" element={<ThanksPage />} />
      </Routes>

    </>

  );
}

export default App;