import Landing from "../components/landing"
import AppSection from '../components/appSection';
import Discount from "../components/discount"
import FeaturedIn from '../components/featuredIn';
import MeetNurse from '../components/meetNurse';
import Socials from '../components/socials';
import Testimonials from '../components/testimonials';
import WhatIs from '../components/what_is';
import ZoomMeetings from '../components/zoomMeetings';
import MembershipSection from "../components/membershipSection";
import InfoFloat from "../components/infoFloat";
import { useEffect } from "react";
import SignUpPopup from "../components/signupPopup";
import React, { useRef } from 'react';
import axios from "axios";
import Swal from "sweetalert2";


const today = new Date();

// Add 7 days to today's date
const nextWeek = new Date(today.getTime() + 120 * 60 * 1000);

// Format the date into the desired format
const formattedDate = nextWeek.toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false // Set to true if you want 12-hour format with AM/PM
});

const SubscriptionPage = () => {
    useEffect(() => {
        document.querySelector("body").style.overflow = "auto"
    }, []);

    const toCheckout = async () => {
        Swal.fire({
            title: "Please Wait...",
            html: `<span id="img">
                <img src="/butt-talk-gif.gif" alt="Logo" style="width:150px" />
            </span>`,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                document.body.style.overflow = 'hidden';
            },
            willClose: () => {
                // Enable scrolling back
                document.body.style.overflow = 'auto';
            }
        });
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`);
        window.location.href = response.data.url;

    }

    return (
        <>
            <SignUpPopup />

            <main>
                <InfoFloat
                    text="Step 2: Choose your plan below"
                />
                <MembershipSection />
                <Landing handleClick={toCheckout} verify={true} />
                {formattedDate && (<Discount countDownDate={formattedDate} />)}




                <WhatIs handleClick={toCheckout} verify={true} />
                <Socials />
                <AppSection handleClick={toCheckout} verify={true} />
                <ZoomMeetings handleClick={toCheckout} verify={true} />
                <MeetNurse handleClick={toCheckout} verify={true} />
                <FeaturedIn />
                <Testimonials handleClick={toCheckout} verify={true} />
            </main>
        </>
    );
}

export default SubscriptionPage;