
import { Link } from 'react-router-dom';
import Footer from '../components/footer';

import InfoFloat100 from '../components/infoFloat100';
import SignUpPopup from '../components/signupPopup';

import BlobbedBackground from "../utils/blobbedBackground";



const ThanksPage = () => {

    return (
        <>

            <SignUpPopup />
            <main>
                <InfoFloat100
                    text="100%"
                />
                <BlobbedBackground id="landing_section" section_type="section">
                    <div style={{ marginTop: '100px' }}>
                        <img src='./checkemail.gif' alt='check-email' style={{ height: '300px' }} />
                    </div>
                    <Link to="/">
                        <button className="join_button">Back to home</button>
                    </Link>

                </BlobbedBackground>

            </main>


        </>
    );
}

export default ThanksPage;